const OCX_EVENT = `
<body class="body">
  <div class="div-block-88">
    <div class="header wf-section">
      <div class="container w-container">
        <div class="div-block-11">
          <div class="div-block-7">
            <div class="div-block-6"></div>
            <div class="div-block-8">
              <div class="text-block-2">ZURICH</div>
              <div class="div-block-9"></div>
              <div class="text-block-2">MAR. 9</div>
              <div class="div-block-10">
                <div class="text-block-2">1:30 pm</div>
              </div>
            </div>
          </div>
          <div class="div-block-47">
            <a href="https://go.screver.com/capptoo/registration-scr-ocx" class="link-block w-inline-block">
              <div class="button btn-header">join the event</div>
            </a>
            <a href="https://go.screver.com/capptoo/registration-scr-ocx" class="link-block-11 w-inline-block">
              <div class="w-embed">
                
                <div id="gooey-button" class="gooey-button-header">
                  <span class="gooey-button-text">join the event</span>
                  <span class="bubbles">
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                    <span class="bubble"></span>
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="gooey">
                            <!-- in="sourceGraphic" -->
                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                            <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                            <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                        </filter>
                    </defs>
                  </svg>
                </div>
              </div>
            </a>
            <div class="text-block-2-copy">Limited Capacity</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-sec wf-section">
      <div class="div-block-82"></div>
      <div class="container hero-inner w-container">
        <div class="div-block">
          <h1 class="h1">New era of<br>experiences</h1>
          <div class="div-block-4">
            <div class="text-block">Mar.9</div>
            <div class="div-block-5"></div>
            <div class="text-block">1:30  PM</div>
          </div>
        </div>
      </div>
      <div class="div-block-81"><img src="/images/webflow/ocx-event/radial.svg" loading="lazy" alt="" class="image-23">
        <div class="w-embed">
          <style>
            .hero-sec {
              mix-blend-mode: lighten;
            }

            .hero-sec .background-video {
              background-image: radial-gradient(50% 50% at 50% 50%, rgba(21, 21, 23, 0) 0%, #151517 100%);
            }

            .hero-sec .background-video video {
              mix-blend-mode: hard-light;
            }

            @media screen and (min-width: 2561px) {
              .hero-sec .div-block-81 {
                top: calc(50% - 750px)
              }
            }
          </style>
        </div>
        <div data-poster-url="/videos/webflow/ocx-event/Untitled-poster-00001.jpg"
          data-video-urls="/videos/webflow/ocx-event/Untitled-transcode.mp4,/videos/webflow/ocx-event/Untitled-transcode.webm"
          data-autoplay="true" data-loop="true" data-wf-ignore="true"
          class="background-video w-background-video w-background-video-atom"><video
            id="833c54ba-2b8c-eb5d-dea9-e232f426ed67-video" autoplay="" loop=""
            style="background-image:url(&quot;/videos/webflow/ocx-event/Untitled-poster-00001.jpg&quot;)" muted=""
            playsinline="" data-wf-ignore="true" data-object-fit="cover">
            <source src="/videos/webflow/ocx-event/Untitled-transcode.mp4" data-wf-ignore="true">
            <source src="/videos/webflow/ocx-event/Untitled-transcode.webm" data-wf-ignore="true">
          </video></div>
      </div>
    </div>
    <div data-w-id="d256e41d-2566-3fa7-c2e0-b9052148f2a7" class="welcome-sec welcome-anim wf-section">
      <div class="div-block-83"><img src="/images/webflow/ocx-event/CX.png" loading="lazy" sizes="100vw"
          srcset="/images/webflow/ocx-event/CX-p-500.png 500w, /images/webflow/ocx-event/CX-p-800.png 800w, /images/webflow/ocx-event/CX-p-1080.png 1080w, /images/webflow/ocx-event/CX.png 1344w"
          alt="" class="image-93"><img src="/images/webflow/ocx-event/OMNICHANNEL.png" loading="lazy" sizes="100vw"
          srcset="/images/webflow/ocx-event/OMNICHANNEL-p-500.png 500w, /images/webflow/ocx-event/OMNICHANNEL-p-800.png 800w, /images/webflow/ocx-event/OMNICHANNEL-p-1080.png 1080w, /images/webflow/ocx-event/OMNICHANNEL-p-1600.png 1600w, /images/webflow/ocx-event/OMNICHANNEL-p-2000.png 2000w, /images/webflow/ocx-event/OMNICHANNEL.png 2954w"
          alt="" class="image-92"><img src="/images/webflow/ocx-event/EX.png" loading="lazy" sizes="100vw"
          srcset="/images/webflow/ocx-event/EX-p-500.png 500w, /images/webflow/ocx-event/EX-p-800.png 800w, /images/webflow/ocx-event/EX-p-1080.png 1080w, /images/webflow/ocx-event/EX.png 1234w"
          alt="" class="image-94"></div>
      <div class="div-block-85 container">
        <div class="div-block-86"><img src="/images/webflow/ocx-event/C.png" loading="lazy" alt="" class="image-c"><img
            src="/images/webflow/ocx-event/X.png" loading="lazy" alt="" class="image-x-left"></div>
        <div class="div-block-87"><img src="/images/webflow/ocx-event/E.png" loading="lazy" alt="" class="image-e"><img
            src="/images/webflow/ocx-event/X.png" loading="lazy" alt="" class="image-x-right"></div><img
          src="/images/webflow/ocx-event/OMNICHANNEL_1.png" loading="lazy"
          sizes="(max-width: 1439px) 100vw, (max-width: 1919px) 1400px, 1478px"
          srcset="/images/webflow/ocx-event/OMNICHANNEL_1-p-500.png 500w, /images/webflow/ocx-event/OMNICHANNEL_1-p-800.png 800w, /images/webflow/ocx-event/OMNICHANNEL_1-p-1080.png 1080w, /images/webflow/ocx-event/OMNICHANNEL_1.png 1478w"
          alt="" class="image-95">
      </div>
      <div class="w-embed">
        <style>
          .div-block-85.container {
            top: calc(50vh - 200px);
          }
        </style>
      </div>
    </div>
    <div class="section-5 wf-section"><img src="/images/webflow/ocx-event/cx-ex-img_1.png" loading="lazy"
        data-w-id="f21b6111-0e5c-61c7-2fe8-1aef953b8cd7" sizes="100vw"
        srcset="/images/webflow/ocx-event/cx-ex-img_1-p-500.png 500w, /images/webflow/ocx-event/cx-ex-img_1-p-800.png 800w, /images/webflow/ocx-event/cx-ex-img_1-p-1080.png 1080w, /images/webflow/ocx-event/cx-ex-img_1-p-1600.png 1600w, /images/webflow/ocx-event/cx-ex-img_1-p-2000.png 2000w, /images/webflow/ocx-event/cx-ex-img_1.png 2251w"
        alt="" class="image"><img src="/images/webflow/ocx-event/Frame-2616_1.png" loading="lazy"
        data-w-id="c37d19d5-e991-5347-81ac-673618647226" sizes="100vw"
        srcset="/images/webflow/ocx-event/Frame-2616_1-p-500.png 500w, /images/webflow/ocx-event/Frame-2616_1-p-800.png 800w, /images/webflow/ocx-event/Frame-2616_1-p-1080.png 1080w, /images/webflow/ocx-event/Frame-2616_1.png 1128w"
        alt="" class="image-mobile">
      <div class="container w-container">
        <div class="div-block-15">
          <div id="user-name-container" class="text-block-4"><span class="text-span-2"> </span> We invite you to an
            event where we introduce the next evolution of customer experience and our platform Screver
            OCX.<br>‍<br>We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how
            AI shapes Voice of the Customer strategies.</div>
          <div class="w-embed w-script">
            <script>
              const insertName = () => {
                const userContainer = document.querySelector('#user-name-container');
                const userSpan = userContainer.querySelector('span');
                if (!userContainer && !userSpan) return;
                const urlParams = new URLSearchParams(window.location.search);
                const userName = urlParams.get("name");
                const content = userName ?
                  \`Hi  <u style="font: inherit; color: #F1FB5A; text-decoration:none;">\${userName} 😉</u>\` :
                  "Hi there 😉 "
                userSpan.innerHTML = content;
              };
              insertName();
            </script>
          </div>
        </div>
      </div>
    </div>
    <div class="unique-format-sec wf-section">
      <div class="container w-container">
        <h2 class="h2">a unique format</h2>
        <div class="w-layout-grid grid">
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-11.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">150+ industry executives<br></div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-1Variant7.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">iconic/hip location<br></div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-13.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">new features and cases on CX and EX<br></div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-14.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">live music - electro-funk, deep house &amp; smooth jazz<br></div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-12_1.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">grower champagne tasting<br></div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-12.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">poker, blackjack &amp; roulette challenge</div>
          </div>
          <div id="w-node-_1b644a0b-0cc7-f804-1ff9-2d612f5a8f83-2f5a8f83" class="div-block-16"><img
              src="/images/webflow/ocx-event/Property-1Variant8.svg" loading="lazy" alt="" class="image-2">
            <div class="text-block-5">skydiving - jet stream - innovative energy sources</div>
          </div>
        </div>
      </div>
    </div>
    <div class="companies-sec wf-section">
      <div class="container w-container">
        <div class="div-block-18">
          <h2 class="h2">fun and cool people to network with</h2>
          <div class="div-block-17">
            <div class="text-reg">The more kind, considerate, smart, and expert the attendees, the livelier and more
              extraordinary our event. This is why we thoroughly chose our audience, so we will all enjoy an outstanding
              and unforgettable experience. Limited capacity.</div>
          </div>
        </div>
        <div class="w-layout-grid grid-2"><img src="/images/webflow/ocx-event/logo-energy-vault.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-energy-vault-p-500.png 500w, /images/webflow/ocx-event/logo-energy-vault-p-800.png 800w, /images/webflow/ocx-event/logo-energy-vault.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-europaprk.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 93vw, (max-width: 991px) 96vw, 874px"
            srcset="/images/webflow/ocx-event/logo-europaprk-p-500.png 500w, /images/webflow/ocx-event/logo-europaprk-p-800.png 800w, /images/webflow/ocx-event/logo-europaprk.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-glacier.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-glacier-p-500.png 500w, /images/webflow/ocx-event/logo-glacier-p-800.png 800w, /images/webflow/ocx-event/logo-glacier.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-google.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-google-p-500.png 500w, /images/webflow/ocx-event/logo-google-p-800.png 800w, /images/webflow/ocx-event/logo-google.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-grand-casino.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-grand-casino-p-500.png 500w, /images/webflow/ocx-event/logo-grand-casino-p-800.png 800w, /images/webflow/ocx-event/logo-grand-casino.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-msd.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-msd-p-500.png 500w, /images/webflow/ocx-event/logo-msd.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-nestle.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-nestle-p-500.png 500w, /images/webflow/ocx-event/logo-nestle-p-800.png 800w, /images/webflow/ocx-event/logo-nestle.png 873w"
            class="logo-item"><img src="/images/webflow/ocx-event/logo-zhaw.png" loading="lazy"
            id="w-node-_5622791c-c909-cdd2-02ec-e8848a02b661-8a02b661" alt=""
            sizes="(max-width: 479px) 97vw, (max-width: 991px) 96vw, (max-width: 1439px) 98vw, (max-width: 1919px) 1368px, 1568px"
            srcset="/images/webflow/ocx-event/logo-zhaw-p-500.png 500w, /images/webflow/ocx-event/logo-zhaw-p-800.png 800w, /images/webflow/ocx-event/logo-zhaw.png 873w"
            class="logo-item"></div>
      </div><img src="/images/webflow/ocx-event/arrow.svg" loading="lazy"
        data-w-id="1e28f072-34b8-b768-5444-4d7f5fa48d64" alt="" class="image-3">
    </div>
    <div id="speaker-swiper" class="section-4 wf-section">
      <div class="container w-container">
        <div class="w-embed">
          <style>
            .swiper {
              width: 100%;
              height: 100%;
            }

            .swiper-slide {
              background-position: center;
              background-size: cover;
            }

            .swiper-slide img {
              display: block;
              width: 100%;
              object-fit: cover;
            }

            body {
              background-color: #151517;
              color: #fff;
            }

            .ocx-slick-container {
              margin-top: 100px;
              padding: 100px 0;
              margin-right: auto;
              margin-left: auto;
              position: relative;
            }

            .slider-video {
              background-image: radial-gradient(50% 50% at 50% 50%, rgba(21, 21, 23, 0) 0%, #151517 100%)
            }

            .slider-video video {
              mix-blend-mode: hard-light;
            }

            .ocx-slick-container * {
              color: #fff;
            }

            .ocx-slick-container p {
              margin: 0;
            }

            .ocx-slick-container__title {
              position: absolute;
              top: 100px;
              left: 0;
              width: 100%;
              max-width: 960px;
              font-size: 120px;
              font-family: 'Epilogue';
              font-weight: 800;
              line-height: 90%;
              letter-spacing: -0.02em;
              z-index: 2;
            }

            .ocx-slick-speaker-slider {}

            .ocx-slick-slide {
              display: flex !important;
              justify-content: flex-start;
              align-items: flex-end;
              margin: 0 auto;
            }

            .ocx-slick-text-wrap {
              margin-right: -60px;
              padding-top: 40px;
              padding-bottom: 40px;
              max-width: 614px;
              border-top: 1px solid #fff;
              transition: opacity 0.4s ease-in-out;
              z-index: 2;
              pointer-events: none;
            }

            .ocx-slick-img {
              position: relative;
              transform: translate(0, 300px) rotate(0deg) scale(0.5);
              transition: all 0.2s linear;
              max-width: 510px;
              width: 100%;
            }

            .ocx-slick-img .slide-noise {
              position: absolute;
              inset: 0;
              opacity: 0.125;
            }

            .ocx-slick-img::after {
              content: '';
              position: absolute;
              inset: 0;
              background-image: linear-gradient(250.31deg, rgba(0, 0, 0, 0) 56.15%, rgba(0, 0, 0, 0.7) 98.83%);
              z-index: 1;
            }

            .ocx-slick-img img {
              width: 100%;
              height: 100%;
            }

            .ocx-slick-title {
              margin-bottom: 8px !important;
              font-family: 'Epilogue';
              font-weight: 800;
              font-size: 36px;
              line-height: 104%;
              letter-spacing: -0.02em;
            }

            .ocx-slick-text {}

            .ocx-slick-text__position {
              margin-bottom: 30px !important;
              font-family: 'Aeroport', sans-serif;
              font-weight: 400;
              font-size: 18px;
              line-height: 120%;
              opacity: 0.8;
            }

            .ocx-slick-text__description {
              font-family: 'Aeroport', sans-serif;
              font-weight: 400;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.02em;
              opacity: 0.8;
            }

            /* slick */
            .swiper-slide-active {
              z-index: 1;
            }

            .swiper-slide-active .ocx-slick-img {
              box-shadow: 50px 20px 35px -2px rgba(0, 0, 0, 35%);
            }

            .swiper {
              padding-top: 100px !important;
              padding-bottom: 100px !important;
            }

            .swiper-slide:not(.swiper-slide-active) .ocx-slick-text-wrap {
              opacity: 0 !important;
            }

            .swiper-slide-next .ocx-slick-img {
              transform: translate(280px, 50px) rotate(10deg) scale(0.85);
            }

            .swiper-slide-prev .ocx-slick-img::after {
              background-image: linear-gradient(250.31deg, rgba(0, 0, 0, 0) 56.15%, rgba(0, 0, 0, 0.7) 98.83%);
            }

            .swiper-slide-active .ocx-slick-img,
            .swiper-slide-prev .ocx-slick-img,
            .swiper-slide-next .ocx-slick-img {
              transition: all 0.4s ease-in-out;
            }

            .ocx-slick-container .swiper-pagination-bullets {
              bottom: 196px !important;
              left: -4px;
              text-align: left !important;
            }

            .ocx-slick-container .swiper-pagination-bullet {
              --swiper-pagination-bullet-size: 114px;
              height: 1px !important;
              background-color: #fff !important;
              border-radius: 100px;
              transition: all 0.2s linear;
            }

            .ocx-slick-container .swiper-button-next,
            .ocx-slick-container .swiper-button-prev {
              width: 81px;
              height: 81px;
              top: calc(100% - 260px);
              transition: all 0.2s ease-in-out;
              border-radius: 50%;
              overflow: hidden;
            }

            .ocx-slick-container .swiper-button-prev {
              left: calc(80%);
              right: initial;
            }

            .ocx-slick-container .swiper-button-next {
              left: calc(80% + 100px);
              right: initial;
            }

            .ocx-slick-container .swiper-button-next::after,
            .ocx-slick-container .swiper-button-prev::after {
              content: none;
            }

            .ocx-slick-container .swiper-button-next *,
            .ocx-slick-container .swiper-button-prev * {
              transition: all 0.2s ease-in-out;
            }

            .swiper,
            .swiper-wrapper {
              height: auto;
            }

            .swiper-count {
              position: absolute;
              top: 100px;
              right: 0;
              font-size: 100px;
              line-height: 102px;
              font-weight: 700;
              letter-spacing: -0.05em;
              font-family: 'Epilogue', sans-serif;
            }

            .user-select-disable {
              user-select: none;
            }

            .swiper-count,
            .swiper-count__total,
            .swiper-count__current {
              color: rgba(255, 255, 255, 0);
              -webkit-text-stroke-width: 0.5px;
              -webkit-text-stroke-color: #fff;
            }

            @media screen and (min-width: 1401px) {
              .ocx-slick-img {
                width: 570px;
                height: 680px;
              }
            }

            @media screen and (max-width: 1400px) {
              .ocx-slick-img {
                width: 490px;
                height: 630px;
              }
            }

            @media screen and (min-width: 1200px) {

              .ocx-slick-container .swiper-button-next:hover svg path,
              .ocx-slick-container .swiper-button-prev:hover svg path {
                stroke: #141414 !important;
              }

              .ocx-slick-container .swiper-button-next:hover svg rect,
              .ocx-slick-container .swiper-button-prev:hover svg rect {
                stroke: #F1FB5A !important;
              }

              .ocx-slick-container .swiper-button-next:hover,
              .ocx-slick-container .swiper-button-prev:hover {
                background-color: #F1FB5A;
              }
            }

            @media screen and (max-width: 1199px) {
              .ocx-slick-img {
                height: auto;
              }

              .ocx-slick-container__title {
                font-size: 100px;
              }

              .ocx-slick-title {
                font-size: 30px;
              }

              .ocx-slick-text__position {
                font-size: 16px;
              }

              .ocx-slick-text__description {
                font-size: 18px;
              }

              .ocx-slick-container .swiper-button-prev {
                left: initial;
                right: 120px;
              }

              .ocx-slick-container .swiper-button-next {
                left: initial;
                right: 10px;
              }
            }

            @media screen and (max-width: 991px) {
              .ocx-slick-container__title {
                font-size: 82px;
              }

              .ocx-slick-text-wrap {
                max-width: 450px;
              }

              .ocx-slick-container__title {
                top: 64px;
              }

              .ocx-slick-title {
                font-size: 24px;
              }

              .ocx-slick-text__position {
                margin-bottom: 16px !important;
                font-size: 14px;
              }

              .ocx-slick-text__description {
                font-size: 16px;
              }

              .ocx-slick-container .swiper-pagination-bullet {
                --swiper-pagination-bullet-size: 83px;
              }

              .swiper-slide-next .ocx-slick-img {
                transform: translate(170px, 50px) rotate(10deg) scale(0.85);
              }
            }

            @media screen and (max-width: 767px) {
              .ocx-slick-container {
                margin-top: 40px;
              }

              .ocx-slick-slide {
                flex-direction: column-reverse;
              }

              .ocx-slick-container__title {
                font-size: 48px;
              }

              .ocx-slick-img img {
                height: 100%;
              }

              .ocx-slick-text-wrap {
                max-width: 100%;
              }

              .ocx-slick-text-wrap {
                margin-right: 0;
              }

              .ocx-slick-text-wrap {
                border-top: 0;
              }

              .swiper-wrapper {
                border-bottom: 1px solid #fff;
              }

              .ocx-slick-container .swiper-pagination-bullets {
                bottom: 120px !important;
                text-align: center !important;
              }

              .ocx-slick-container .swiper-pagination-bullet {
                --swiper-pagination-bullet-size: 12%;
              }

              .swiper-slide-prev .ocx-slick-img,
              .swiper-slide-next .ocx-slick-img {
                transform: translate(0, 0) rotate(0deg) scale(1);
              }

              .ocx-slick-img {
                max-width: 100%;
                width: 100%;
                transform: none;
              }

              .swiper-button-next,
              .swiper-button-prev,
              .swiper-count {
                display: none !important;
              }
            }

            @media screen and (min-width: 768px) {
              .swiper-slide .ocx-slick-img {
                opacity: 0;
                overflow: hidden;
              }

              .swiper-slide-prev .ocx-slick-img,
              .swiper-slide-active .ocx-slick-img,
              .swiper-slide-next .ocx-slick-img {
                opacity: 1;
              }

              .swiper-slide:not(.swiper-slide-active) {
                opacity: 1 !important;
                z-index: -1;
              }

              .swiper-slide-prev .ocx-slick-img {
                transform: translate(-170px, 50px) rotate(-10deg) scale(0.85);
                opacity: 0.2;
              }

              .swiper-slide-active .ocx-slick-img {
                transform: translate(0, 0) rotate(0deg) scale(1);
              }
            }
          </style>
        </div>
        <div class="html-embed w-embed">
          <div class="ocx-slick-container">
            <p class="ocx-slick-container__title">inspiring key speakers</p>
            <div class="ocx-slick-speaker-slider swiper slick-speaker-slider-js">
              <div class="swiper-wrapper">
                <div class="ocx-slick-slide swiper-slide">
                  <div class="ocx-slick-text-wrap">
                    <p class="ocx-slick-title">
                      Our secret is coming out! Introducing The Next Evolution of Screver OCX 💙
                    </p>
                    <div class="ocx-slick-text">
                      <p class="ocx-slick-text__position"><strong>Christian Fillinger</strong>, founder of Capptoo &amp;
                        Screver</p>
                      <p class="ocx-slick-text__description">
                        As a serial entrepreneur, Christian is never happy with the status quo and always on the outlook
                        to
                        shake things up. He continuously explores new business ideas, tests them and makes the promising
                        ones a
                        reality. Currently, there are 14 such ideas/concepts within C2 Inc (Capptoo Incubator).
                      </p>
                    </div>
                  </div>
                  <div class="ocx-slick-img">
                    <img class="slide-noise" src="/images/webflow/ocx-event/noise.gif" alt="">
                    <img src="/images/webflow/ocx-event/img-457.png" alt="Christian Fillinger">
                  </div>
                </div>
                <div class="ocx-slick-slide swiper-slide">
                  <div class="ocx-slick-text-wrap">
                    <p class="ocx-slick-title">
                      Courage for the future – innovation of tomorrow
                    </p>
                    <div class="ocx-slick-text">
                      <p class="ocx-slick-text__position"><strong>Marc Hauser</strong>, professional Swiss
                        skydiver 🪂, artist and entrepreneur</p>
                      <p class="ocx-slick-text__description">
                        As a pioneer who was the first to skydive through the jet stream, Hauser demonstrates the
                        incredible
                        energy potential of high-altitude wind power. BBC World News accompanied him with a three-part
                        series.
                        New ways of harnessing clean energy are one of the hopes for a sustainable future.
                      </p>
                    </div>
                  </div>
                  <div class="ocx-slick-img">
                    <img class="slide-noise" src="/images/webflow/ocx-event/noise.gif" alt="">
                    <img src="/images/webflow/ocx-event/img-458.png" alt="Marc Hauser">
                  </div>
                </div>
                <div class="ocx-slick-slide swiper-slide">
                  <div class="ocx-slick-text-wrap">
                    <p class="ocx-slick-title">
                      Transformation into a new corporate culture. Taking effective measures to increase employee
                      satisfaction
                    </p>
                    <div class="ocx-slick-text">
                      <p class="ocx-slick-text__position"><strong>Jannine Hodel</strong>, HR Director Stadtcasino
                        Baden Group</p>
                      <p class="ocx-slick-text__description">
                        Stadtcasino Baden and its subsidiaries are one of the largest casino operators in Switzerland
                        and active
                        in both the online and traditional gaming space. More than 300,000 visitors enjoy The House of
                        Entertainment, a glittering world with diverse experiences and first-class services every year.
                      </p>
                    </div>
                  </div>
                  <div class="ocx-slick-img">
                    <img class="slide-noise" src="/images/webflow/ocx-event/noise.gif" alt="">
                    <img src="/images/webflow/ocx-event/img-461.png" alt="Jannine Hodel">
                  </div>
                </div>
                <div class="ocx-slick-slide swiper-slide">
                  <div class="ocx-slick-text-wrap">
                    <p class="ocx-slick-title">
                      “Our agile way” – increase agile-maturity based on a self-made assessment
                    </p>
                    <div class="ocx-slick-text">
                      <p class="ocx-slick-text__position"><strong>Dr. Anja Huber</strong>, Agile Support ZHAW
                        Finance & Services
                      </p>
                      <p class="ocx-slick-text__description">
                        The ZHAW is one of the leading universities of applied sciences in Switzerland with over 3'500
                        employees
                        and more than 14'000 students. ZHAW goes agile and is constantly assessing and improving its new
                        way of
                        working.
                      </p>
                    </div>
                  </div>
                  <div class="ocx-slick-img">
                    <img class="slide-noise" src="/images/webflow/ocx-event/noise.gif" alt="">
                    <img src="/images/webflow/ocx-event/img-456.png" alt="Dr. Anja Huber">
                  </div>
                </div>
                <div class="ocx-slick-slide swiper-slide">
                  <div class="ocx-slick-text-wrap">
                    <p class="ocx-slick-title">
                      Customer Experience at Europa-Park Resort, Germany’s largest theme park
                    </p>
                    <div class="ocx-slick-text">
                      <p class="ocx-slick-text__position"><strong>Pascal Weller</strong>, Customer Insights
                        Manager & Project Manager Market Research at Europa-Park
                      </p>
                      <p class="ocx-slick-text__description">
                        Around 5,000 people work at Europa-Park, the Rulantica water world, and the associated themed
                        hotels
                        every day to put a smile on your face. Feedback plays a vital role in continuously improving the
                        guest
                        experience.
                      </p>
                    </div>
                  </div>
                  <div class="ocx-slick-img">
                    <img class="slide-noise" src="/images/webflow/ocx-event/noise.gif" alt="">
                    <img src="/images/webflow/ocx-event/img-459.png" alt="Pascal Weller">
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev user-select-disable">
              <svg xmlns="http://www.w3.org/2000/svg" width="81" height="81" fill="none">
                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M37.393 27.5 24.5 40.5m0 0 12.893 13M24.5 40.5h32"></path>
                <rect width="79" height="79" x="-1" y="1" stroke="#fff" stroke-width="2" rx="39.5"
                  transform="matrix(-1 0 0 1 79 0)"></rect>
              </svg>
            </div>
            <div class="swiper-button-next user-select-disable">
              <svg xmlns="http://www.w3.org/2000/svg" width="81" height="81" fill="none">
                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m43.607 27.5 12.893 13m0 0-12.893 13m12.893-13h-32"></path>
                <rect width="79" height="79" x="1" y="1" stroke="#fff" stroke-width="2" rx="39.5"></rect>
              </svg>
            </div>
            <div class="swiper-count user-select-disable">
              <span class="swiper-count__current user-select-disable"></span>/<span
                class="swiper-count__total user-select-disable"></span>
            </div>
          </div>
        </div>
        <div class="w-embed w-script">
          <script src="https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js"></script>
          <script>
            var swiper = new Swiper(".slick-speaker-slider-js", {
              spaceBetween: 30,
              effect: "fade",
              grabCursor: true,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });
            const swiperCount = () => {
              const slides = [...document.querySelectorAll('.ocx-slick-slide')];
              const slidesCount = slides.length;
              const slidesCurrentWrap = document.querySelector('.swiper-count__current').innerHTML = swiper
                .activeIndex + 1;
              const slidesTotalWrap = document.querySelector('.swiper-count__total').innerHTML = slidesCount;
            };
            swiperCount();
            swiper.on('slideChange', () => swiperCount());
          </script>
        </div>
      </div>
      <div data-poster-url="/videos/webflow/ocx-event/Untitled-poster-00001.jpg"
        data-video-urls="/videos/webflow/ocx-event/Untitled-transcode.mp4,/videos/webflow/ocx-event/Untitled-transcode.webm"
        data-autoplay="true" data-loop="true" data-wf-ignore="true"
        class="slider-video w-background-video w-background-video-atom"><video
          id="36041aa5-3b7a-7ba5-d169-929158822cc2-video" autoplay="" loop=""
          style="background-image:url(&quot;/videos/webflow/ocx-event/Untitled-poster-00001.jpg&quot;)" muted=""
          playsinline="" data-wf-ignore="true" data-object-fit="cover">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.mp4" data-wf-ignore="true">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.webm" data-wf-ignore="true">
        </video></div>
    </div>
    <div data-w-id="7c967916-6fb7-8c03-f9b9-e64cfa221b92" class="speakers wf-section">
      <div class="container w-container">
        <h2 class="h2"><strong>inspiring key speakers</strong></h2>
        <div class="w-layout-grid grid-3">
          <div id="w-node-df9a0586-5f24-7f7a-dbce-5f70fba0d4eb-2dd0ed12" class="speakers-item-1"><img loading="lazy"
              sizes="100vw"
              srcset="/images/webflow/ocx-event/Frame-2616-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-p-1080.png 1080w, /images/webflow/ocx-event/Frame-2616.png 1086w"
              src="/images/webflow/ocx-event/Frame-2616.png" alt="" class="image-4">
            <div class="div-block-21">
              <h3 class="heading">Our secret is coming out! Introducing The Next Evolution of Screver OCX 💙</h3>
              <div class="text-small">Christian Fillinger, founder of Capptoo &amp; Screver</div>
              <div class="div-block-20">
                <div class="text-reg">As a serial entrepreneur, Christian is never happy with the status quo and always
                  on the outlook to shake things up. He continuously explores new business ideas, tests them and makes
                  the promising ones a reality. Currently, there are 14 such ideas/concepts within C2 Inc (Capptoo
                  Incubator). </div>
              </div>
            </div>
          </div>
          <div id="w-node-_66cb8cc7-c286-3476-9568-6874228df24b-2dd0ed12" class="speakers-item-2"><img loading="lazy"
              sizes="100vw"
              srcset="/images/webflow/ocx-event/Frame-2616-1-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-1-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-1.png 1086w"
              src="/images/webflow/ocx-event/Frame-2616-1.png" alt="" class="image-4">
            <div class="div-block-21">
              <h3 class="heading">Courage for the future – innovation of tomorrow</h3>
              <div class="text-small">Marc Hauser, professional Swiss skydiver 🪂, artist and entrepreneur</div>
              <div class="div-block-20">
                <div class="text-reg">As a pioneer who was the first to skydive through the jet stream, Hauser
                  demonstrates the incredible energy potential of high-altitude wind power. BBC World News accompanied
                  him with a three-part series. New ways of harnessing clean energy are one of the hopes for a
                  sustainable future.</div>
              </div>
            </div>
          </div>
          <div id="w-node-_4850fcad-978a-3f31-5622-59008728abeb-2dd0ed12" class="speakers-item-3"><img loading="lazy"
              sizes="100vw"
              srcset="/images/webflow/ocx-event/Frame-2616-4-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-4.png 1086w"
              src="/images/webflow/ocx-event/Frame-2616-4.png" alt="" class="image-4">
            <div class="div-block-21">
              <h3 class="heading">Transformation into a new corporate culture. Taking effective measures to increase
                employee satisfaction</h3>
              <div class="text-small">Jannine Hodel, HR Director Stadtcasino Baden Group</div>
              <div class="div-block-20">
                <div class="text-reg">Stadtcasino Baden and its subsidiaries are one of the largest casino operators in
                  Switzerland and active in both the online and traditional gaming space. More than 300,000 visitors
                  enjoy The House of Entertainment, a glittering world with diverse experiences and first-class services
                  every year.</div>
              </div>
            </div>
          </div>
          <div id="w-node-a460d562-714e-bf02-fcb2-e142cff27992-2dd0ed12" class="speakers-item-4"><img loading="lazy"
              sizes="100vw"
              srcset="/images/webflow/ocx-event/Frame-2616-5-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-5-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-5.png 1086w"
              src="/images/webflow/ocx-event/Frame-2616-5.png" alt="" class="image-4">
            <div class="div-block-21">
              <h3 class="heading">“Our agile way” – increase agile-maturity based on a self-made assessment</h3>
              <div class="text-small">Dr. Anja Huber, Agile Support ZHAW Finance &amp; Services</div>
              <div class="div-block-20">
                <div class="text-reg">The ZHAW is one of the leading universities of applied sciences in Switzerland
                  with over 3&#x27;500 employees and more than 14&#x27;000 students. ZHAW goes agile and is constantly
                  assessing and improving its new way of working.<br></div>
              </div>
            </div>
          </div>
          <div id="w-node-_689fafa5-6bb5-3c75-6d53-287642d84f78-2dd0ed12" class="speakers-item-5"><img loading="lazy"
              sizes="100vw"
              srcset="/images/webflow/ocx-event/Frame-2616-2-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-2-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-2.png 1086w"
              src="/images/webflow/ocx-event/Frame-2616-2.png" alt="" class="image-4">
            <div class="div-block-21">
              <h3 class="heading">Customer Experience at Europa-Park Resort, Germany’s largest theme park</h3>
              <div class="text-small">Pascal Weller, Customer Insights Manager &amp; Project Manager Market Research at
                Europa-Park<br></div>
              <div class="div-block-20">
                <div class="text-reg">Around 5,000 people work at Europa-Park, the Rulantica water world, and the
                  associated themed hotels every day to put a smile on your face. Feedback plays a vital role in
                  continuously improving the guest experience.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="speakers-slider wf-section">
      <div class="container-2 w-container">
        <div data-delay="4000" data-animation="slide" class="slider-2 w-slider" data-autoplay="false" data-easing="ease"
          data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="10"
          data-duration="500" data-infinite="true">
          <div class="slider-content w-slider-mask">
            <div class="testimonials-slide-qn w-slide">
              <div class="slide-content">
                <div id="w-node-_7ff7e884-2742-e368-726e-73199955aa87-9955aa87" class="speakers-item"><img
                    src="/images/webflow/ocx-event/Frame-2616.png" loading="lazy" alt="" sizes="100vw"
                    srcset="/images/webflow/ocx-event/Frame-2616-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-p-1080.png 1080w, /images/webflow/ocx-event/Frame-2616.png 1086w"
                    class="image-4">
                  <div class="div-block-21">
                    <h3 class="heading">Our secret is coming out! Introducing The Next Evolution of Screver OCX 💙</h3>
                    <div class="text-small">Christian Fillinger: Founder of Capptoo &amp; Screver</div>
                    <div class="div-block-20">
                      <div class="text-reg">As a serial entrepreneur, Christian is never happy with the status quo and
                        always on the outlook to shake things up. He continuously explores new business ideas, tests
                        them and makes the promising ones a reality. Currently, there are 14 such ideas/concepts within
                        C2 Inc (Capptoo Incubator). </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonials-slide-qn-copy w-slide">
              <div class="slide-content">
                <div id="w-node-_7ff7e884-2742-e368-726e-73199955aa87-9955aa87" class="speakers-item"><img
                    src="/images/webflow/ocx-event/Frame-2616-1.png" loading="lazy" alt="" sizes="100vw"
                    srcset="/images/webflow/ocx-event/Frame-2616-1-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-1-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-1.png 1086w"
                    class="image-4">
                  <div class="div-block-21">
                    <h3 class="heading">Courage for the future – innovation of tomorrow</h3>
                    <div class="text-small">Marc Hauser, Professional Swiss skydiver 🪂, artist and entrepreneur</div>
                    <div class="div-block-20">
                      <div class="text-reg">As a pioneer who was the first to skydive through the jetstream, Hauser
                        demonstrates the incredible energy potential of high-altitude wind power. BBC World News
                        accompanied him with a three-part series. New ways of harnessing clean energy are one of the
                        hopes for a sustainable future.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-slide">
              <div class="slide-content">
                <div id="w-node-_7ff7e884-2742-e368-726e-73199955aa87-9955aa87" class="speakers-item"><img
                    src="/images/webflow/ocx-event/Frame-2616-4.png" loading="lazy" alt="" sizes="100vw"
                    srcset="/images/webflow/ocx-event/Frame-2616-4-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-4.png 1086w"
                    class="image-4">
                  <div class="div-block-21">
                    <h3 class="heading">Transformation into a new corporate culture. Taking effective measures to
                      increase employee satisfaction</h3>
                    <div class="text-small">Jannine Hodel, HR Director Stadtcasino Baden Group</div>
                    <div class="div-block-20">
                      <div class="text-reg">Stadtcasino Baden and its subsidiaries are one of the largest casino
                        operators in Switzerland and active in both the online and traditional gaming space. More than
                        300,000 visitors enjoy The House of Entertainment, a glittering world with diverse experiences
                        and first-class services every year.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-slide">
              <div class="slide-content">
                <div id="w-node-_7ff7e884-2742-e368-726e-73199955aa87-9955aa87" class="speakers-item"><img
                    src="/images/webflow/ocx-event/Frame-2616-5.png" loading="lazy" alt="" sizes="100vw"
                    srcset="/images/webflow/ocx-event/Frame-2616-5-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-5-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-5.png 1086w"
                    class="image-4">
                  <div class="div-block-21">
                    <h3 class="heading">“Our agile way” - increase agile-maturity based on an self-made assessment</h3>
                    <div class="text-small">Dr. Anja Huber, Agile Support ZHAW Finance &amp; Services</div>
                    <div class="div-block-20">
                      <div class="text-reg">The ZHAW is one of the leading universities of applied sciences in
                        Switzerland with over 3&#x27;500 employees and more than 14&#x27;000 students. ZHAW goes agile
                        and is constantly assessing and improving its new way of working.<br></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-slide">
              <div class="slide-content">
                <div id="w-node-_7ff7e884-2742-e368-726e-73199955aa87-9955aa87" class="speakers-item"><img
                    src="/images/webflow/ocx-event/Frame-2616-2.png" loading="lazy" alt="" sizes="100vw"
                    srcset="/images/webflow/ocx-event/Frame-2616-2-p-500.png 500w, /images/webflow/ocx-event/Frame-2616-2-p-800.png 800w, /images/webflow/ocx-event/Frame-2616-2.png 1086w"
                    class="image-4">
                  <div class="div-block-21">
                    <h3 class="heading">Customer Experience at Germany&#x27;s largest adventure resort</h3>
                    <div class="text-small">Pascal Weller, Customer Insights Manager &amp; Projektleiter Marktforschung
                      at Europa-Park</div>
                    <div class="div-block-20">
                      <div class="text-reg">Around 5,000 people work at Europa-Park, the Rulantica water world, and the
                        associated themed hotels every day to put a smile on your face. Feedback plays a vital role in
                        continuously improving the guest experience.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-arrow w-slider-arrow-left"></div>
          <div class="right-arrow w-slider-arrow-right"></div>
          <div class="slide-nav-2 w-slider-nav w-round"></div>
          <div class="w-embed">
            <style>
              .w-slider-dot {
                background: #333335;
                width: 10px;
                height: 10px;
                overflow: hidden;
                transform: translateZ(0);
              }

              .w-slider-dot.w-active {
                color: #FFFFFF;
                width: 10px;
                height: 10px;
                background: #FFFFFF;
                overflow: hidden;
                transform: translateZ(0);
              }
            </style>
          </div>
        </div>
      </div>
    </div>
    <div data-w-id="2e828f3f-c76e-dac9-fbda-e8311b6b2e25" class="carly-sec wf-section">
      <div class="the-overflow-hidden-mask-copy">
        <div class="the-width-400vh-scrollable-div-2">
          <div class="the-content">
            <div class="text-block-12">Live music</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Vocal</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Piano</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">saxophone</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">DJ</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Funk</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Jazz</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">R&amp;B</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Live music</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Vocal</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Piano</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Live music</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">saxophone</div>
            <div class="div-block-79"></div>
            <div class="text-block-12">Vocal</div>
          </div>
        </div>
      </div>
      <div class="container-carly w-container">
        <div class="div-block-25">
          <div class="div-block-45">
            <div class="div-block-26">
              <div class="div-block-23">
                <div class="text-block-6">Special guest</div>
                <div class="div-block-22"></div>
              </div>
              <div class="h2 h2-black">Carly Simone</div>
            </div>
            <div class="the-overflow-hidden-mask">
              <div class="the-width-400vh-scrollable-div-2">
                <div class="the-content">
                  <div class="text-block-12">Live music</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Vocal</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Piano</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">saxophone</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">DJ</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Funk</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Jazz</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">R&amp;B</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Live music</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Vocal</div>
                  <div class="div-block-79"></div>
                  <div class="text-block-12">Piano</div>
                </div>
              </div>
            </div><img src="/images/webflow/ocx-event/Screenshot-2022-12-01-at-16.58-1.png" loading="lazy"
              sizes="(max-width: 479px) 160px, (max-width: 991px) 250px, (max-width: 1919px) 300px, 450px"
              srcset="/images/webflow/ocx-event/Screenshot-2022-12-01-at-16.58-1-p-500.png 500w, /images/webflow/ocx-event/Screenshot-2022-12-01-at-16.58-1-p-800.png 800w, /images/webflow/ocx-event/Screenshot-2022-12-01-at-16.58-1.png 891w"
              alt="" class="image-5">
          </div>
          <div class="div-block-46">
            <div class="text-block-11">Vote for the song you&#x27;d like to listen live?</div>
            <div class="w-embed">
              <style>
                ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;
                }

                .audio-player {
                  --seek-before-width: 0%;
                  --volume-before-width: 100%;
                  --buffered-width: 0%;
                  padding: 12px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  background: rgba(45, 45, 58, 0.1);
                  border-radius: 21px;
                }

                .audio-player .play-icon {
                  padding: 0;
                  border: 0;
                  background: transparent;
                  cursor: pointer;
                  outline: none;
                  width: 40px;
                  height: 40px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url('https://capptoo.com/globalassets/general/play-icon.svg');
                  transition: transform 0.1s linear;
                  flex: none;
                }

                .audio-player .play-icon:hover {
                  transform: scale(1.05);
                }

                .audio-player .play-icon.pause-icon {
                  background-image: url('https://capptoo.com/globalassets/general/pause-icon.svg');
                }

                .audio-player input[type="range"] {
                  position: relative;
                  -webkit-appearance: none;
                  /* width: 48%; */
                  /* margin: 0; */
                  padding: 0;
                  outline: none;
                }

                .audio-player input[type="range"]::-webkit-slider-runnable-track {
                  width: 100%;
                  height: 3px;
                  cursor: pointer;
                  background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
                }

                .audio-player input[type="range"]::-webkit-slider-thumb {
                  position: relative;
                  -webkit-appearance: none;
                  box-sizing: content-box;
                  height: 11px;
                  width: 11px;
                  border-radius: 50%;
                  background-color: #fff;
                  cursor: pointer;
                  margin: -4px 0 0 0;
                }

                .audio-player input[type="range"]:active::-webkit-slider-thumb {
                  transform: scale(1.2);
                  background: #fff;
                }

                .audio-player input[type="range"]::-moz-range-track {
                  width: 100%;
                  height: 3px;
                  cursor: pointer;
                  background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
                }

                .audio-player input[type="range"]::-moz-range-progress {
                  background-color: #fff;
                }

                .audio-player input[type="range"]::-moz-focus-outer {
                  border: 0;
                }

                .audio-player input[type="range"]::-moz-range-thumb {
                  box-sizing: content-box;
                  border: 1px solid #fff;
                  height: 15px;
                  width: 15px;
                  border-radius: 50%;
                  background-color: #fff;
                  cursor: pointer;
                }

                .audio-player input[type="range"]:active::-moz-range-thumb {
                  transform: scale(1.2);
                }

                .audio-player input[type="range"]::-ms-track {
                  width: 100%;
                  height: 3px;
                  cursor: pointer;
                  background: transparent;
                  border: solid transparent;
                  color: transparent;
                }

                .audio-player input[type="range"]::-ms-fill-lower {
                  background-color: #fff;
                }

                .audio-player input[type="range"]::-ms-fill-upper {
                  background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
                }

                .audio-player input[type="range"]::-ms-thumb {
                  box-sizing: content-box;
                  border: 1px solid #fff;
                  height: 15px;
                  width: 15px;
                  border-radius: 50%;
                  background-color: #fff;
                  cursor: pointer;
                }

                .audio-player .sound-vote {
                  margin-left: auto;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                  width: 40px;
                  height: 40px;
                  border-radius: 12px;
                  border: 0;
                  background-color: rgba(255, 255, 255, 1);
                  background-size: 20px 25px;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-image: url('https://capptoo.com/globalassets/general/fire-icon.png');
                  transition: all 0.2s linear;
                  cursor: pointer;
                  flex: none;
                }

                .audio-player .sound-vote svg,
                .audio-player .sound-vote span {
                  display: none;
                }

                .audio-player .sound-vote:not(.voted):hover {
                  background-color: rgba(255, 255, 255, 0);
                  transform: scale(1.2);
                  cursor: pointer;
                }

                .audio-player .sound-vote.my-vote,
                .audio-player .sound-vote.voted {
                  width: 57px;
                  height: 40px;
                  background-color: rgba(16, 16, 16, 0.2);
                  background-size: none;
                  background-image: none;
                  border-radius: 10px;
                }

                .audio-player .sound-vote.voted svg,
                .audio-player .sound-vote.voted span,
                .audio-player .sound-vote.my-vote svg,
                .audio-player .sound-vote.my-vote span {
                  display: block;
                  pointer-events: none;
                }

                .audio-player .sound-vote.voted span,
                .audio-player .sound-vote.my-vote span {
                  font-family: 'Aeroport', sans-serif;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  letter-spacing: -0.055em;
                  color: #1C1C1F;
                }

                .audio-player .seek-slider {
                  background: #1C1C1F;
                  opacity: 0.1;
                  border-radius: 176.015px;
                  height: 11px;
                  width: 75%;
                  margin: 0 8px;
                }

                #player-wrapper .song-name {
                  margin-top: 5px;
                  margin-left: 12px;
                  margin-bottom: 0;
                  font-family: 'Aeroport', sans-serif;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: -0.055em;
                  color: #000000;
                }

                #player-wrapper {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-wrap: wrap;
                  padding-left: 0;
                }

                #player-wrapper>li {
                  display: flex;
                  flex-direction: column;
                  width: calc(25% - 24px);
                  padding: 15px 12px;
                }

                .block-vote {
                  pointer-events: none;
                }

                @media screen and (max-width: 1023px) {

                  /* #player-wrapper {
        display: block;
      } */
                  #player-wrapper>li+li {
                    margin-top: 12px;
                  }

                  #player-wrapper>li {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(50% - 24px);
                    padding: 12px;
                  }

                  .audio-player {
                    padding: 8px;
                    width: 80%;
                  }

                  .audio-player .seek-slider {
                    width: 80%;
                  }

                  /* .audio-player .play-icon,
      .audio-player .sound-vote {
        width: 34px;
        height: 34px;
      } */
                  .audio-player .sound-vote {
                    background-size: 20px 23px;
                  }

                  #player-wrapper .song-name {
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: 8px;
                    width: 20%;
                  }
                }

                @media screen and (max-width: 991px) {
                  .audio-player {
                    padding: 8px;
                    border-radius: 16px;
                    flex: 0 0 70%;
                    gap: 8px;
                  }

                  #player-wrapper .song-name {
                    margin-top: 1px;
                    margin-left: 0px;
                  }
                }

                @media screen and (max-width: 767px) {
                  #player-wrapper {
                    display: block;
                  }

                  #player-wrapper>li+li {
                    margin-top: 8px;
                  }

                  #player-wrapper>li {
                    width: 100%;
                    padding: 0;
                  }

                  .audio-player .seek-slider {
                    width: 80%;
                    height: 6px;
                  }

                  .audio-player input[type="range"]::-webkit-slider-thumb {
                    width: 6px;
                    height: 6px;
                    margin: -2px 0 0 0;
                  }

                  .audio-player .sound-vote {
                    width: 42px;
                    height: 42px;
                  }

                  #player-wrapper .song-name {
                    margin-left: 0 !important;
                  }
                }

                @media screen and (max-width: 575px) {
                  .audio-player .seek-slider {
                    width: 55%;
                  }

                  #player-wrapper .song-name {
                    font-size: 14px;
                    margin-top: 0;
                  }
                }

                @media screen and (min-width: 768px) {
                  #player-wrapper {
                    margin-left: -12px;
                  }
                }
              </style>
            </div>
            <div class="w-embed w-script">
              <ul id="player-wrapper">
                <script>
                  const array = fetch('https://screver-ocx-event.test-dev.site/get.php')
                    .then(response => response.json())
                    .then(response => {
                      const data = response.slice(1);
                      const localStorageIsVote = localStorage.getItem('isVote') ? localStorage.getItem('isVote') : [];
                      const rows = data.map(item => {
                          return \`
              <li>
                <div class="audio-player">
                  <audio class="audio-track" src="\${item[1]}" preload="metadata"
                    loop></audio>
                  <button class="play-icon"></button>
                  <input type="range" class="seek-slider" max="100" value="0">
                  <button type="button"
                    class="sound-vote \${localStorageIsVote.includes(item[0]) ? 'my-vote' : ''}"
                    data-index="\${item[0]}"
                    data-vote="\${item[3]}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                      <path stroke="#101010" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m4.213 9.44 2.667 2.667 6.666-6.667" /></svg>
                      <span>
                        \${item[3]}
                      </span>
                  </button>
                </div>
                <p class="song-name">\${item[2]}</p>
              </li>
            \`
                        })
                        .filter(i => i)
                        .join('');
                      document
                        .getElementById('player-wrapper')
                        .innerHTML = rows;
                    })
                    .then(() => {
                      const audioPlayerContainer = document.querySelectorAll('.audio-player');
                      const allAudio = document.querySelectorAll('.audio-track');
                      const allPlayBtns = document.querySelectorAll('.play-icon');
                      const createAudioPlayer = (player) => {
                        const playIconContainer = player.querySelector('.play-icon');
                        const audio = player.querySelector('.audio-track');
                        const seekSlider = player.querySelector('.seek-slider');
                        const soundVote = player.querySelector('.sound-vote');
                        playIconContainer.addEventListener('click', (e) => {
                          Array.from(allAudio).forEach(track => {
                            if (track.currentTime !== 0) {
                              track.pause();
                            }
                          });
                          Array.from(allPlayBtns).forEach(btn => btn.classList.remove('pause-icon'));
                          if (audio.currentTime === 0) {
                            audio.play();
                            requestAnimationFrame(whilePlaying);
                            e.target.classList.add('pause-icon');
                            Array.from(allAudio).forEach(track => {
                              if (track.currentTime !== 0) {
                                track.currentTime = 0;
                              }
                            });
                          }
                          if (audio.currentTime > 0) {
                            audio.currentTime = 0;
                            audio.pause();
                            cancelAnimationFrame(raf);
                            e.target.classList.remove('pause-icon');
                          }
                        });
                        const showRangeProgress = (rangeInput) => {
                          if (rangeInput === seekSlider) player.style.setProperty('--seek-before-width',
                            rangeInput
                            .value /
                            rangeInput.max * 100 + '%');
                          else player.style.setProperty('--volume-before-width', rangeInput.value / rangeInput
                            .max *
                            100 +
                            '%');
                        }
                        seekSlider.addEventListener('input', (e) => {
                          showRangeProgress(e.target);
                        });
                        let raf = null;
                        const setSliderMax = () => {
                          seekSlider.max = Math.floor(audio.duration);
                        }
                        const displayBufferedAmount = () => {
                          const bufferedAmount = Math.floor(audio.buffered.end(audio.buffered.length - 1));
                          player.style.setProperty('--buffered-width',
                            \`\${(bufferedAmount / seekSlider.max) * 100}%\`);
                        }
                        const whilePlaying = () => {
                          seekSlider.value = Math.floor(audio.currentTime);
                          player.style.setProperty('--seek-before-width',
                            \`\${seekSlider.value / seekSlider.max * 100}%\`);
                          raf = requestAnimationFrame(whilePlaying);
                        }
                        if (audio.readyState > 0) {
                          setSliderMax();
                        } else {
                          audio.addEventListener('loadedmetadata', () => {
                            setSliderMax();
                          });
                        }
                        seekSlider.addEventListener('input', () => {
                          if (!audio.paused) {
                            cancelAnimationFrame(raf);
                          }
                        });
                        seekSlider.addEventListener('change', () => {
                          audio.currentTime = seekSlider.value;
                          if (!audio.paused) {
                            requestAnimationFrame(whilePlaying);
                          }
                        });
                        // if (localStorage.getItem('isVote')) {
                        //   return;
                        // } else {
                        soundVote.addEventListener('click', (e) => {
                          e.preventDefault();
                          e.stopPropagation()
                          const index = e.target.getAttribute('data-index');
                          const vote = e.target.getAttribute('data-vote');
                          const data = new FormData();
                          data.append('index', index);
                          data.append('vote', Number(vote) + 1);
                          let isVote = e.target.classList.contains('voted') ||
                            e.target.classList.contains('my-vote');
                          if (isVote) {
                            data.append('vote', Number(vote) - 1);
                            e.target.classList.remove('voted');
                            e.target.classList.remove('my-vote');
                          } else {
                            data.append('vote', Number(vote) + 1);
                            e.target.classList.add('voted');
                            e.target.classList.add('my-vote');
                          }
                          fetch('https://screver-ocx-event.test-dev.site/update.php', {
                              method: 'POST',
                              body: data
                            })
                            .then(response => response.json())
                            .then(() => {
                              let storage = [];
                              storage = JSON.parse(localStorage.getItem('isVote')) || [];
                              if (!isVote) {
                                storage.push(index);
                                localStorage.setItem('isVote', JSON.stringify(storage));
                              } else {
                                if (storage.indexOf(index) > -1) {
                                  storage.splice(storage.indexOf(index), 1);
                                  localStorage.setItem('isVote', JSON.stringify(storage));
                                }
                              }
                            })
                          // Array.from(document.querySelectorAll('.sound-vote')).forEach(btn => btn.style.pointerEvents =
                          //   'none');
                        })
                        // }
                      };
                      audioPlayerContainer.forEach(createAudioPlayer);
                    })
                </script>
              </ul>
            </div>
          </div>
        </div><img src="/images/webflow/ocx-event/Component-4.svg" loading="lazy" alt="" class="image-19-copy">
      </div>
    </div>
    <div class="program wf-section">
      <div class="container w-container">
        <h2 class="h2">program</h2>
        <div class="program-inner">
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Introduction – welcome at Screver &amp; Capptoo</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Christian Fillinger</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Courage for the future – innovation of tomorrow</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Marc Hauser</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Our secret is coming out! Introducing The Next Evolution of Screver OCX 💙</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Christian Fillinger</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Transformation into a new corporate culture. Taking effective measures to increase
              employee satisfaction, Stadtcasino Baden Group</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Jannine Hodel</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Break-out sessions for design &amp; storytelling in CX; Unicorns – our way</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Various presenters</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">“Our agile way” – increase agile-maturity based on a self-made assessment, ZHAW </div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Dr. Anja Huber</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Customer Experience at Europa-Park Resort, Germany’s largest theme park</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Pascal Weller</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Live music: electro-funk, deep house, and smooth jazz</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold">Carly Simone</div>
          </div>
          <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b79e" class="div-block-29">
            <div class="text-reg">Networking, grower champagne tasting 🥂, flying dinner🍴, poker, blackjack &amp;
              roulette ♣️♦️</div>
            <div data-w-id="df12dd8d-1b52-e235-37c1-efb2de58b7a1" class="text-reg-bold"><br></div>
          </div>
        </div>
      </div><img src="/images/webflow/ocx-event/radial.svg" loading="lazy" alt="" class="image-23-copy">
      <div data-poster-url="/videos/webflow/ocx-event/Untitled-poster-00001.jpg"
        data-video-urls="/videos/webflow/ocx-event/Untitled-transcode.mp4,/videos/webflow/ocx-event/Untitled-transcode.webm"
        data-autoplay="true" data-loop="true" data-wf-ignore="true"
        class="background-video-program w-background-video w-background-video-atom"><video
          id="590d865c-b08d-5bb4-5973-c12c263a3b03-video" autoplay="" loop=""
          style="background-image:url(&quot;/videos/webflow/ocx-event/Untitled-poster-00001.jpg&quot;)" muted=""
          playsinline="" data-wf-ignore="true" data-object-fit="cover">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.mp4" data-wf-ignore="true">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.webm" data-wf-ignore="true">
        </video></div>
      <div class="w-embed">
        <style>
          .program.wf-section .background-video-program {
            mix-blend-mode: lighten;
          }

          @media screen and (min-width: 2561px) {
            .program.wf-section video {
              object-fit: contain;
              background-image: none !important;
            }
          }
        </style>
      </div>
    </div>
    <div class="location wf-section">
      <div class="container w-container">
        <div class="div-block-34">
          <div class="div-block-35"><img src="/images/webflow/ocx-event/lets-meet-at.svg" loading="lazy" alt=""
              class="image-24">
            <div class="div-block-33">
              <a href="https://www.kraftwerk.host" target="_blank" class="link-block-10 w-inline-block">
                <h3 class="h3">Kraftwerk Zürich </h3><img src="/images/webflow/ocx-event/arrow_1.svg" loading="lazy"
                  alt="" class="image-91">
              </a>
              <div class="text-reg">
                <a href="https://goo.gl/maps/PHYYpeNodcieaeFa6" target="_blank" class="link-2">Selnaustrasse 25, 8001
                  Zürich, Switzerland</a>
              </div>
            </div>
          </div>
          <div class="w-layout-grid grid-4">
            <div id="w-node-_428be6c1-5c8b-1b12-36c8-a2bb314c4d14-2dd0ed12" class="div-block-32"><img class="image-6"
                src="/images/webflow/ocx-event/place-img-1.png" alt="" style="opacity:0"
                sizes="(max-width: 479px) 97vw, (max-width: 767px) 90vw, (max-width: 991px) 70vw, (max-width: 1439px) 83vw, (max-width: 1919px) 1168px, 1368px"
                data-w-id="5eba15cb-ddbb-209c-481d-cfe9821f9c29"
                id="w-node-_5eba15cb-ddbb-209c-481d-cfe9821f9c29-2dd0ed12" loading="lazy"
                srcset="/images/webflow/ocx-event/place-img-1-p-500.png 500w, /images/webflow/ocx-event/place-img-1-p-800.png 800w, /images/webflow/ocx-event/place-img-1-p-1080.png 1080w, /images/webflow/ocx-event/place-img-1.png 1467w"><img
                src="/images/webflow/ocx-event/place-img-3.png" loading="lazy"
                data-w-id="22bedb4b-1f4a-c7f7-6034-550ef81e55c0"
                id="w-node-_22bedb4b-1f4a-c7f7-6034-550ef81e55c0-2dd0ed12"
                srcset="/images/webflow/ocx-event/place-img-3-p-500.png 500w, /images/webflow/ocx-event/place-img-3-p-800.png 800w, /images/webflow/ocx-event/place-img-3-p-1080.png 1080w, /images/webflow/ocx-event/place-img-3.png 1467w"
                sizes="(max-width: 479px) 97vw, (max-width: 767px) 90vw, (max-width: 991px) 70vw, (max-width: 1439px) 83vw, (max-width: 1919px) 1168px, 1368px"
                alt="" class="image-8"></div>
            <div id="w-node-e5efdc1b-6a02-dc41-df3c-bfceee324bbf-2dd0ed12" class="div-block-31"><img class="image-7"
                src="/images/webflow/ocx-event/place-img-2.png" alt="" style="opacity:0"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 70vw, (max-width: 1439px) 83vw, (max-width: 1919px) 1168px, 1368px"
                data-w-id="a767f59e-6344-1e6f-3178-eb863216a5bc"
                id="w-node-a767f59e-6344-1e6f-3178-eb863216a5bc-2dd0ed12" loading="lazy"
                srcset="/images/webflow/ocx-event/place-img-2-p-500.png 500w, /images/webflow/ocx-event/place-img-2-p-800.png 800w, /images/webflow/ocx-event/place-img-2-p-1080.png 1080w, /images/webflow/ocx-event/place-img-2.png 1518w"><img
                class="image-9" src="/images/webflow/ocx-event/place-img-3-1.png" alt="" style="opacity:0"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 70vw, (max-width: 1439px) 83vw, (max-width: 1919px) 1168px, 1368px"
                data-w-id="0a5dce0f-0df1-90dc-6a8c-45cdef2f5b10"
                id="w-node-_0a5dce0f-0df1-90dc-6a8c-45cdef2f5b10-2dd0ed12" loading="lazy"
                srcset="/images/webflow/ocx-event/place-img-3-1-p-500.png 500w, /images/webflow/ocx-event/place-img-3-1-p-800.png 800w, /images/webflow/ocx-event/place-img-3-1-p-1080.png 1080w, /images/webflow/ocx-event/place-img-3-1.png 1521w">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="christian-sec wf-section">
      <div class="container w-container">
        <div class="div-block-36">
          <div class="div-block-37"><img src="/images/webflow/ocx-event/img.png" loading="lazy"
              sizes="(max-width: 479px) 97vw, (max-width: 991px) 47vw, (max-width: 1439px) 32vw, (max-width: 1919px) 476px, 500px"
              srcset="/images/webflow/ocx-event/img-p-500.png 500w, /images/webflow/ocx-event/img-p-800.png 800w, /images/webflow/ocx-event/img-p-1080.png 1080w, /images/webflow/ocx-event/img-p-1600.png 1600w, /images/webflow/ocx-event/img.png 1689w"
              alt="" class="image-10">
            <div class="div-block-38"><img src="/images/webflow/ocx-event/Signature.svg" loading="lazy" alt="">
              <div class="text-block-9">Christian</div>
              <div class="text-block-10">founder of Capptoo &amp; Screver</div>
            </div>
          </div>
          <div>
            <h1 class="h2">keen to attend?</h1>
            <div class="text-reg">I am thrilled to meet you on March 9th and share some exciting news about Screver OCX
              and the future of Customer Experience. Can&#x27;t wait to see you all 😉🥂</div>
            <a href="https://go.screver.com/capptoo/registration-scr-ocx" class="link-block-11 w-inline-block"><div class="w-embed">
    
    <div id="gooey-button" class="christian">
    		<span class="gooey-button-text">join the event</span>
        
        <span class="bubbles">
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
            <span class="bubble"></span>
        </span>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
              <defs>
                  <filter id="gooey">
                      <!-- in="sourceGraphic" -->
                      <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                      <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                      <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                  </filter>
              </defs>
            </svg>
          </div>
          </div>
          </a>
          </div>
        </div>
      </div>
    </div>
    <div class="team wf-section">
      <div class="container w-container">
        <div class="div-block-39">
          <h2 class="h2 h2-centre">looking forward to meeting you</h2><img
            src="/images/webflow/ocx-event/HERE-WE-ARE.svg" loading="lazy" alt="" class="image-11">
        </div>
        <div class="div-block-40-copy"><img class="team-item" src="/images/webflow/ocx-event/Nastya.png" alt=""
            style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="8007cd62-9915-fd3a-c989-486612cf27f5" id="w-node-_8007cd62-9915-fd3a-c989-486612cf27f5-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Nastya-p-500.png 500w, /images/webflow/ocx-event/Nastya.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Andy.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="6e00f7da-22dd-8606-ea8b-d4fbd52b9aaf" id="w-node-_6e00f7da-22dd-8606-ea8b-d4fbd52b9aaf-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Andy-p-500.png 500w, /images/webflow/ocx-event/Andy.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Dejan.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="2aaee4d7-9e0e-e459-426e-0f2b1f453a80" id="w-node-_2aaee4d7-9e0e-e459-426e-0f2b1f453a80-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Dejan-p-500.png 500w, /images/webflow/ocx-event/Dejan.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Nastya-1.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="6a6b2c05-f6d3-e0a5-d728-af25c9a4906b" id="w-node-_6a6b2c05-f6d3-e0a5-d728-af25c9a4906b-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Nastya-1-p-500.png 500w, /images/webflow/ocx-event/Nastya-1.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Ira.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="598c7347-2525-bab1-e64e-b3b78c3291d9" id="w-node-_598c7347-2525-bab1-e64e-b3b78c3291d9-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Ira-p-500.png 500w, /images/webflow/ocx-event/Ira.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Roma.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="0c1f8cf1-700b-dab0-727a-b669dea35190" id="w-node-_0c1f8cf1-700b-dab0-727a-b669dea35190-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Roma-p-500.png 500w, /images/webflow/ocx-event/Roma.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Marina.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="64287474-a92e-30d9-0725-0216f4a2681f" id="w-node-_64287474-a92e-30d9-0725-0216f4a2681f-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Marina-p-500.png 500w, /images/webflow/ocx-event/Marina.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Ivana-Sisko.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="97ed48d9-2deb-44c2-a243-d3899c3ca709" id="w-node-_97ed48d9-2deb-44c2-a243-d3899c3ca709-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Christian-p-500.png 500w, /images/webflow/ocx-event/Ivana-Sisko.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Svitlana.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="740d4324-3a9a-578c-99d0-b7a4ebe53742" id="w-node-_740d4324-3a9a-578c-99d0-b7a4ebe53742-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Svitlana-p-500.png 500w, /images/webflow/ocx-event/Svitlana.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Davor.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="98915de4-3881-0d9a-6526-a309d30c061d" id="w-node-_98915de4-3881-0d9a-6526-a309d30c061d-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Davor-p-500.png 500w, /images/webflow/ocx-event/Davor.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Mila.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="8e7c3d25-0d54-6158-d65b-df8a42b15a7c" id="w-node-_8e7c3d25-0d54-6158-d65b-df8a42b15a7c-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Mila-p-500.png 500w, /images/webflow/ocx-event/Mila.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Alex.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="34cff7e6-48cb-772e-08b1-0db8615c7c1e" id="w-node-_34cff7e6-48cb-772e-08b1-0db8615c7c1e-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Alex-p-500.png 500w, /images/webflow/ocx-event/Alex.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Ivana.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="da7b4aa4-95f3-5f4e-154d-457c914f90f9" id="w-node-da7b4aa4-95f3-5f4e-154d-457c914f90f9-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Ivana-p-500.png 500w, /images/webflow/ocx-event/Ivana.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Krys_1.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="fa25e009-5a4c-76bc-39a5-e61b65345e92" id="w-node-fa25e009-5a4c-76bc-39a5-e61b65345e92-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Krys_1-p-500.png 500w, /images/webflow/ocx-event/Krys_1.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Victoria.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="1fdc6b22-273c-afca-1004-9b42f53b952e" id="w-node-_1fdc6b22-273c-afca-1004-9b42f53b952e-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Victoria-p-500.png 500w, /images/webflow/ocx-event/Victoria.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Natasha.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="89cdf183-b897-b3a9-2785-c8e0640bc1b8" id="w-node-_89cdf183-b897-b3a9-2785-c8e0640bc1b8-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Natasha-p-500.png 500w, /images/webflow/ocx-event/Natasha.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Tanja.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="bb09cdd1-5e5f-b1ff-3984-ecf8556c2857" id="w-node-bb09cdd1-5e5f-b1ff-3984-ecf8556c2857-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Tanja-p-500.png 500w, /images/webflow/ocx-event/Tanja.png 549w"><img
            class="team-item" src="/images/webflow/ocx-event/Igor.png" alt="" style="opacity:0"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            data-w-id="c42bf246-7095-37e3-b2a0-65c5c30d57e4" id="w-node-c42bf246-7095-37e3-b2a0-65c5c30d57e4-2dd0ed12"
            loading="lazy"
            srcset="/images/webflow/ocx-event/Igor-p-500.png 500w, /images/webflow/ocx-event/Igor.png 549w"><img
            src="/images/webflow/ocx-event/Krys.png" loading="lazy" style="opacity:0"
            data-w-id="a2490503-034b-da5a-4a92-7f0adfad283b"
            srcset="/images/webflow/ocx-event/Krys-p-500.png 500w, /images/webflow/ocx-event/Krys.png 549w"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            alt="" class="team-item"><img src="/images/webflow/ocx-event/Tony.png" loading="lazy" style="opacity:0"
            data-w-id="a2490503-034b-da5a-4a92-7f0adfad283c"
            srcset="/images/webflow/ocx-event/Tony-p-500.png 500w, /images/webflow/ocx-event/Tony.png 549w"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            alt="" class="team-item"><img src="/images/webflow/ocx-event/Ana.png" loading="lazy" style="opacity:0"
            data-w-id="a2490503-034b-da5a-4a92-7f0adfad283d"
            srcset="/images/webflow/ocx-event/Ana-p-500.png 500w, /images/webflow/ocx-event/Ana.png 549w"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            alt="" class="team-item"><img src="/images/webflow/ocx-event/Max.png" loading="lazy" style="opacity:0"
            data-w-id="92ebadca-ba5a-6be1-334b-f82ac29e4c33"
            srcset="/images/webflow/ocx-event/Max-p-500.png 500w, /images/webflow/ocx-event/Max.png 549w"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            alt="" class="team-item"><img src="/images/webflow/ocx-event/Nastya_1.png" loading="lazy" style="opacity:0"
            data-w-id="ce6f2cf2-660c-630f-de4e-b92faf49d402"
            srcset="/images/webflow/ocx-event/Nastya_1-p-500.png 500w, /images/webflow/ocx-event/Nastya_1.png 550w"
            sizes="(max-width: 479px) 29vw, (max-width: 991px) 17vw, (max-width: 1439px) 14vw, (max-width: 1919px) 191.515625px, 219.515625px"
            alt="" class="team-item"></div>
      </div><img src="/images/webflow/ocx-event/radial.svg" loading="lazy" alt="" class="image-23-copy">
      <div data-poster-url="/videos/webflow/ocx-event/Untitled-poster-00001.jpg"
        data-video-urls="/videos/webflow/ocx-event/Untitled-transcode.mp4,/videos/webflow/ocx-event/Untitled-transcode.webm"
        data-autoplay="true" data-loop="true" data-wf-ignore="true"
        class="background-video-team w-background-video w-background-video-atom"><video
          id="1a53d9cf-f41e-160d-f72b-792f7557a44e-video" autoplay="" loop=""
          style="background-image:url(&quot;/videos/webflow/ocx-event/Untitled-poster-00001.jpg&quot;)" muted=""
          playsinline="" data-wf-ignore="true" data-object-fit="cover">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.mp4" data-wf-ignore="true">
          <source src="/videos/webflow/ocx-event/Untitled-transcode.webm" data-wf-ignore="true">
        </video></div>
      <div class="w-embed">
        <style>
          .team.wf-section {
            mix-blend-mode: lighten;
          }

          @media screen and (min-width: 2561px) {
            .team.wf-section video {
              background-image: none !important;
              object-fit: contain;
            }
          }
        </style>
      </div>
    </div>
    <div class="footer wf-section">
      <div class="container w-container">
        <div class="div-block-42">
          <div class="div-block-44">
            <a href="https://capptoo.com" target="_blank" class="link-block-2 w-inline-block"><img
                src="/images/webflow/ocx-event/capptoo-logo.png" loading="lazy" alt="" class="image-16"></a>
            <a href="https://screver.com" target="_blank" class="link-block-3 w-inline-block"><img
                src="/images/webflow/ocx-event/screver-logo.png" loading="lazy" alt="" class="image-17"></a>
          </div>
          <div class="div-block-43">
            <div class="div-block-48">
              <a href="https://www.facebook.com/screverr" target="_blank" class="link-block-4 w-inline-block"><img
                  src="/images/webflow/ocx-event/Facebook.svg" loading="lazy" alt=""></a>
              <a href="https://www.instagram.com/screverr/" target="_blank" class="link-block-4 w-inline-block"><img
                  src="/images/webflow/ocx-event/Instagram.svg" loading="lazy" alt=""></a>
            </div>
            <a href="https://www.linkedin.com/company/screver/" target="_blank" class="button-small w-inline-block"><img
                src="/images/webflow/ocx-event/Facebook-1.svg" loading="lazy" alt="" class="image-18">
              <div class="text-small text-small-black">Connect in LinkedIn</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="noise"></div>
  </div>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=639c6e154d03cc3f8fd0ed11"
    type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous">
  </script>
  <script src="/js/webflow.ocx-event.js" type="text/javascript"></script>
  <!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
</body>`;

export default OCX_EVENT;
